"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const Carrossel = (props) => {
    var _a, _b;
    const imagens = props.imagens;
    const [count, setCount] = (0, react_1.useState)(0);
    const [progress, setProgress] = (0, react_1.useState)('');
    const carrossel = (0, react_1.useRef)(null);
    const [disabled, setDisable] = (0, react_1.useState)(false);
    const [timeCarrossel, setTimeCarrossel] = (0, react_1.useState)(false);
    const disabledFC = () => setDisable(false);
    const next = () => {
        setProgress('');
        if (carrossel.current) {
            if (count === (imagens.length - 1)) {
                carrossel.current.scrollLeft = 0;
                setCount(0);
            }
            else {
                carrossel.current.scrollLeft = Math.floor(carrossel.current.scrollLeft) + Math.floor(carrossel.current.offsetWidth);
                setCount(count + 1);
            }
        }
        setDisable(true);
    };
    const prev = () => {
        setProgress('');
        if (carrossel.current) {
            if (Math.floor(carrossel.current.scrollLeft) === 0) {
                carrossel.current.scrollLeft = carrossel.current.offsetWidth * (imagens.length - 1);
                setCount(imagens.length - 1);
            }
            else {
                carrossel.current.scrollLeft = Math.floor(carrossel.current.scrollLeft) - Math.floor(carrossel.current.offsetWidth);
                setCount(count - 1);
            }
        }
        setDisable(true);
    };
    (0, react_1.useEffect)(() => {
        const cooldown = setTimeout(disabledFC, 500);
        const timeout = setTimeout(next, 5000);
        setProgress('progress-animation 5s infinite');
        return () => {
            clearTimeout(timeout);
            clearTimeout(cooldown);
        };
    }, [count]);
    (0, react_1.useEffect)(() => {
        const cooldown = setTimeout(() => setTimeCarrossel(true), 100);
        return () => {
            clearTimeout(cooldown);
        };
    }, []);
    return (react_1.default.createElement("div", null,
        react_1.default.createElement("div", { className: 'carrossel-container', ref: carrossel },
            imagens.map((img, id) => {
                return (react_1.default.createElement("img", { src: img.img, key: id }));
            }),
            react_1.default.createElement("div", { className: 'carrossel', style: { display: timeCarrossel ? '' : 'none', height: (_a = carrossel.current) === null || _a === void 0 ? void 0 : _a.clientHeight, width: (_b = carrossel.current) === null || _b === void 0 ? void 0 : _b.clientWidth } },
                props.btnSeta ? react_1.default.createElement("button", { className: 'btn-carrossel', onClick: prev, style: { display: disabled ? 'none' : '' } },
                    react_1.default.createElement("i", { className: "fa fa-angles-left" })) : null,
                props.link ? react_1.default.createElement(react_router_dom_1.Link, { to: `/produto/${imagens[count].link}`, className: 'link' }) : react_1.default.createElement(react_router_dom_1.Link, { to: '#', className: 'link' }),
                props.btnSeta ? react_1.default.createElement("button", { className: 'btn-carrossel', onClick: next, style: { display: disabled ? 'none' : '' } },
                    react_1.default.createElement("i", { className: "fa fa-angles-right" })) : null,
                props.progressBar ?
                    react_1.default.createElement("div", { className: "container" },
                        react_1.default.createElement("div", { className: "container progress-bar", style: { animation: progress } })) : null)),
        props.btnCircle ?
            react_1.default.createElement("div", { className: 'circle-btns' }, imagens.map((img, id) => {
                return (react_1.default.createElement("button", { style: { background: count === id ? 'black' : 'gray' }, className: 'circle-btn', onClick: () => { setCount(id), setProgress(''), carrossel.current ? carrossel.current.scrollLeft = carrossel.current.offsetWidth * id : null; }, disabled: count === id ? true : false, key: id }));
            })) : null));
};
exports.default = Carrossel;
