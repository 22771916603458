const Menu = [
/*   {
    path: '#', title: 'AGV',
    children: [
      {
        path: '#', title: 'CAPACETE FECHADO', children: [
          { path: '/market/10020238', title: 'K1' },
          { path: '/market/10020069', title: 'K3 SV' },
        ]
      },
      {
        path: '#', title: 'FORRAÇÃO', children: [
          { path: '/market/010010061023', title: 'BLADE' },
          { path: '/market/010010061028', title: 'COMPACT' },
          { path: '/market/010010061047', title: 'GPTECH' },
          { path: '/market/010010061053', title: 'K3' },
          { path: '/market/010010061055', title: 'K3 SV' },
          { path: '/market/010010061056', title: 'K4' },
          { path: '/market/010010061061', title: 'LONGWAY' },
          { path: '/market/010010061070', title: 'GP/CORSA' },
          { path: '/market/010010061114', title: 'T2' },
          { path: '/market/010010061127', title: 'AX8' },
          { path: '/market/010010061179', title: 'K1' },
          { path: '/market/010010061196', title: 'SPORTMODULAR' },
        ]
      },
      {
        path: '#', title: 'ACESSÓRIOS', children: [
          { path: '/market/010010004', title: 'BAVETE' },
          { path: '/market/010010007', title: 'BOCHECHEIRA' },
          { path: '/market/01001008', title: 'NARIGUEIRA' },
          { path: '/market/01006005', title: 'VISEIRA' },
          { path: '/market/010040115', title: 'SAIDA DE AR' },
          { path: '/market/010040117', title: 'SPOILER' },
          { path: '/market/0100101', title: 'OUTROS' },
        ]
      },
    ]
  } */{
    path: '#', title: 'AGV',
    children: [
      {
        path: '#', title: 'CAPACETE FECHADO', children: [
          { path: '/market/10020068', title: 'K3 NEW' },
          { path: '/market/10020871', title: 'K1 S NEW' },
          { path: '/market/10020069', title: 'K3 SV BR' },
          { path: '/market/10020238', title: 'K1' },
        ]
      },
      {
        path: '#', title: 'FORRAÇÃO', children: [
          { path: '/market/10010068', title: 'K3 NEW' },
          { path: '/market/10010871', title: 'K1 S NEW' },
          { path: '/market/10010069', title: 'K3 SV BR' },
          { path: '/market/10010238', title: 'K1' },
        ]
      },
      {
        path: '#', title: 'ACESSÓRIOS', children: [
          { path: '/market/10010004', title: 'BAVETE' },
          { path: '/market/10010007', title: 'BOCHECHEIRA' },
          { path: '/market/1001008', title: 'NARIGUEIRA' },
          { path: '/market/1006005', title: 'VISEIRA' },
          { path: '/market/10040115', title: 'SAIDA DE AR' },
          { path: '/market/10040117', title: 'SPOILER' },
          { path: '/market/100101', title: 'OUTROS' },
        ]
      },
    ]
  },
  {
    path: '#', title: 'DAINESE'
  },
  {
    path: '#', title: 'NORISK',
    children: [
      {
        path: '#', title: 'CAPACETE FECHADO', children: [
          { path: '/market/070020352', title: 'VIPER GT' },
          { path: '/market/70020336', title: 'SUPRA' },
          { path: '/market/070020332', title: 'STRADA' },
          { path: '/market/070020036', title: 'FF302' },
          { path: '/market/070020310', title: 'RAZOR' },
        ]
      },
      {
        path: '#', title: 'CAPACETE ABERTO', children: [
          { path: '/market/070020268', title: 'DARTH' },
          { path: '/market/070020280', title: 'ORION' },
          { path: '/market/070020851', title: 'DOWNTOWN' },
        ]
      },
      {
        path: '#', title: 'CAPACETE ARTICULADO', children: [
          { path: '/market/070020199', title: 'ROUTE FF345' },
          { path: '/market/070020253', title: 'FORCE' },
          { path: '/market/070020348', title: 'MOTION' },
        ]
      },
      {
        path: '#', title: 'JAQUETA', children: [
          { path: '/market/0700500662209', title: 'NR-01' },
          { path: '/market/0700500662213', title: 'NR-01 LADY' },
          { path: '/market/0700500662210', title: 'NR-02' },
          { path: '/market/0700500662214', title: 'NR-02 LADY' },
        ]
      },
      {
        path: '#', title: 'MACACÃO', children: [
          { path: '/market/0700503372249', title: 'SS' },
        ]
      },
      {
        path: '#', title: 'CAPA DE CHUVA', children: [
          { path: '/market/0700502842259', title: 'SHIELD' },
          { path: '/market/0700502842260', title: 'SHIELD LADY' },
        ]
      },
    ]
  },
  {
    path: '#', title: 'LS2',
    children: [
      {
        path: '#', title: 'CAPACETE FECHADO', children: [
          { path: '/market/060020276', title: 'CHALLENGER FF327' },
          { path: '/market/060020248', title: 'VECTOR EVO FF397' },
          { path: '/market/060020300', title: 'STORM FF800' },
          { path: '/market/060020039', title: 'FF320 STREAM' },
          { path: '/market/060020235', title: 'RAPID FF353' },
          { path: '/market/060020044', title: 'FF358' },
          { path: '/market/060020252', title: 'FF323 ARROW C EVO' },
          { path: '/market/060020041', title: 'FF323 ARROW R' },
          { path: '/market/060020311', title: 'FF805 THUNDER C' },
        ]
      },
      {
        path: '#', title: 'CAPACETE ABERTO', children: [
          { path: '/market/060020194', title: 'VERSO OF570' },
          { path: '/market/060020227', title: 'SPITFIRE OF599' },
          { path: '/market/060020290', title: 'AIRLOW OF562' },
        ]
      },
      {
        path: '#', title: 'CAPACETE ARTICULADO', children: [
          { path: '/market/060020295', title: 'SCOPE FF902' },
          { path: '/market/060020223', title: 'VALIANT FF399' },
          { path: '/market/060020294', title: 'VALIANT II FF900' },
        ]
      },
      {
        path: '#', title: 'CAPACETE OFF-ROAD', children: [
          { path: '/market/060020146', title: 'MX436 PIONEER' },
          { path: '/market/060020281', title: 'MX436 PIONEER EVO' },
          { path: '/market/060020317', title: 'EXPLORER C MX701' },
          { path: '/market/060020306', title: 'MX700 SUBVERTER EVO' },
          { path: '/market/060020285', title: 'FAST MX437' },
        ]
      },

      {
        path: '#', title: 'CALÇA', children: [
          { path: '/market/0600500152252', title: 'NORWAY' },
          { path: '/market/0600500151614', title: 'CHART' },
          { path: '/market/0600500151615', title: 'CHART LADY' },
          { path: '/market/0600500152032', title: 'VENTO' },
          { path: '/market/0600500152287', title: 'VENTO LADY' },
        ]
      },
      {
        path: '#', title: 'JAQUETA', children: [
          { path: '/market/0600500662284', title: 'SEPANG' },
          { path: '/market/0600500662285', title: 'SEPANG LADY' },
          { path: '/market/0600500662286', title: 'RIVA' },
          { path: '/market/0600500662252', title: 'NORWAY' },
          { path: '/market/0600500662153', title: 'METROPOLIS EVO' },
          { path: '/market/0600500662152', title: 'AIRY' },
          { path: '/market/0600500661613', title: 'GALLANT' },
          { path: '/market/0600500662037', title: 'GATE' },
          { path: '/market/0600500662038', title: 'BULLET' },
          { path: '/market/0600500661606', title: 'TEIDE' },
          { path: '/market/0600500661764', title: 'SERRA' },
          { path: '/market/0600500661765', title: 'SERRA LADY' },
          { path: '/market/0600500662035', title: 'ALBA' },
          { path: '/market/0600500662036', title: 'ALBA LADY' },
          { path: '/market/0600500661611', title: 'COMMUTER' },
        ]
      },
      {
        path: '#', title: 'LUVA', children: [
          { path: '/market/0600500762201', title: 'SNOW' },
          { path: '/market/0600500762052', title: 'SWIFT' },
          { path: '/market/0600500762051', title: 'SPARK' },
          { path: '/market/0600500761841', title: 'RUST' },
          { path: '/market/0600500762053', title: 'URBS' },
        ]
      },
      {
        path: '#', title: 'CAPA DE CHUVA', children: [
          { path: '/market/0600502842101', title: 'FLUXUS EVO' },
          { path: '/market/0600502842102', title: 'FLUXUS LADY EVO' },
        ]
      },
    ]
  },
  {
    path: '#', title: 'RACE TECH',
    children: [
      {
        path: '#', title: 'CAPACETE FECHADO', children: [
          { path: '/market/080020333', title: 'SECTOR' },
          { path: '/market/180020333', title: 'RACE TECH BR CAPACETE SECTOR' },
        ]
      },
      {
        path: '#', title: 'JAQUETA', children: [
          { path: '/market/0800500660388', title: 'FAST' },
          { path: '/market/0800500662137', title: 'FAST AIR' },
          { path: '/market/0800500662136', title: 'FAST LADY' },
          { path: '/market/0800500662135', title: 'FAST AIR LADY' },
          { path: '/market/0800500662205', title: 'FAST WINTER' },
        ]
      },
      {
        path: '#', title: 'LUVA', children: [
          { path: '/market/0700502842259', title: 'FURIOUS' },
        ]
      },
    ]
  },


]

export default Menu;
